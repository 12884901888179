import { App, reactive } from 'vue'
import io from 'socket.io-client'
import { useToast } from 'vuestic-ui/web-components'
import { SocketNotificationDataType } from '../types/common'
import { useTransactionsStore } from '../stores/bb-transactions'
import { useOutputsStore } from '../stores/bb-outputs'

export const socket = {
  install(app: App) {
    const state = reactive({
      connected: false,
    })

    const { init } = useToast()

    const URL = import.meta.env.VITE_WS_URL

    const authToken = JSON.parse(localStorage.getItem('Auth') ?? '{}').token ?? ''

    const socket = io(URL)
    socket.on('connect', () => {
      state.connected = true

      socket.emit('auth_admin', { token: authToken })
    })

    socket.on('connection', () => {
      state.connected = true
      init({
        message: 'Конект',
      })
    })

    socket.on('disconnect', () => {
      state.connected = false
    })

    socket.on('notification', (data: SocketNotificationDataType) => {
      if (Notification.permission === 'granted') {
        new Notification(data.title, {
          body: data.body,
          tag: 'renotify',
          // renotify: true,
        })
      }

      if (data.page.toLowerCase() === 'transactions') {
        useTransactionsStore().getTransactions()
      }

      if (data.page.toLowerCase() === 'outputs') {
        useOutputsStore().getOutputs()
      }
    })

    app.provide('socket', socket)
  },
}
